<template>
  <div class="relative w-full h-full overflow-y-auto">

    <div class="align-top bg-white rounded-lg py-4 pl-4 inline-block mr-4 mb-4">
      <span class="font-bold text-xl">Essensbuchungen</span>
      <label>Projekt</label>
      <select v-model="project" >
        <option value="0">Bitte Auswählen</option>
        <option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >
          {{ project.name }}
        </option>
      </select>
      <label>Jahr</label>
      <select
        v-model="this.selectedYear"
        class="inline-block"
      >
        <option value="2024">2024</option>
        <option value="2025">2025</option>
      </select>
      <label>Kalenderwoche</label>
      <input
        type="number"
        min="1"
        max="52"
        class="border-2 border-gray-200 px-2 py-1"
        v-model="selectedWeek"
      />
      <div class="mt-4 text-center">
        <button class="w-96 mx-2" @click="exportWeekByContract(index)">
          Buchungen exportieren (nach Vertrag)
        </button><br>
        <button class="w-96 mx-2 mt-4" @click="exportWeek(index)">
          Buchungen exportieren (nach Essen)
        </button>
      </div>
    </div>
    <div class="align-top bg-white rounded-lg py-4 pl-4 inline-block">
      <span class="font-bold text-xl">Essensabholungen</span>
      <label>Projekt</label>
      <select v-model="project" >
        <option value="0">Alle Projekte</option>
        <option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >
          {{ project.name }}
        </option>
      </select>
      <label>Datum</label>
      <input type="date" v-model="selectedDate">
      <input id="wholeWeek" type="checkbox" class="small" v-model="wholeWeek">
      <label for="wholeWeek">Ganze Woche</label>
      <div class="mt-4 text-center">
        <button class="w-96 mx-2 mt-4" @click="exportPickups(index)">
          Abholungen exportieren
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: bold;
}
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #e6e6e6;
  /* Set the background color for even rows */
}
</style>
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Mealplanning",
  data() {
    return {
      projects: "",
      project: "0",
      selectedYear: new Date().getFullYear(),
      selectedWeek: 0,
      selectedDate: new Date().toISOString().slice(0, 10),
      wholeWeek: false
    };
  },

  methods: {
    exportPickups(){
      const url =
        process.env.VUE_APP_BE_URL +
        `/mealplan/export/pickups/${this.project}/${this.selectedDate}?wholeWeek=${this.wholeWeek}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    exportWeek() {
      const url =
        process.env.VUE_APP_BE_URL +
        `/mealplan/export/${this.project}/${this.selectedYear}${this.selectedWeek}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    exportWeekByContract(index) {
      const url =
        process.env.VUE_APP_BE_URL +
        `/mealplan/export/byContract/${this.project}/${this.selectedYear}${this.selectedWeek}`;
      const win = window.open(url, "_blank");
      win.focus();
    },
    
    getWeekOfYear() {
      let date = new Date();
      let firstDay = new Date(date.getFullYear(), 0, 1);
      let days = Math.floor((date - firstDay) / (24 * 60 * 60 * 1000));
      this.selectedWeek = Math.ceil((days + firstDay.getDay() + 1) / 7);
    },
    getMaxISOWeek(year) {
      const lastDayOfYear = new Date(year, 11, 31); // December 31st
      const dayOfWeek = lastDayOfYear.getDay() || 7; // Ensure Sunday is 7
      const lastISOWeek = Math.ceil(
        (lastDayOfYear.getDate() - dayOfWeek + 10) / 7
      );
      return lastISOWeek === 53 ? 53 : 52; // Adjust if the year ends with week 53
    },

    getProjects() {
      let vm = this;
      API.getProjectsForEating((r, status) => {
        if (status === 200) {
          vm.projects = r.reverse();
        }
      });
    },
  },
  created() {
    this.getProjects();
    this.getWeekOfYear();
  },
};
</script>