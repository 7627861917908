<template>
  <div
    class="h-full bg-white z-20 sm:inline-block fixed top-0 left-0"
    :class="expand ? 'w-full md:w-64 inline-block' : 'w-16 hidden'"
  >
    <div class="relative h-20 text-center p-2 inline-block w-full">
      <img
        src="@/assets/logo.png"
        class="max-w-full max-h-full inline-block"
        alt="Rescanner logo"
      />
    </div>

    <div :class="expand ? 'px-4' : 'text-center'">
      <div v-if="expand" class="w-full text-right">
        <font-awesome-icon
          icon="arrow-left"
          class="text-3xl text-primary cursor-pointer"
          @click="$emit('toggleMenu')"
        />
      </div>
      <font-awesome-icon
        v-else
        icon="bars"
        class="text-3xl text-primary cursor-pointer"
        @click="$emit('toggleMenu')"
      />
      <div class="w-full">
        <a
          href="/"
          class="inline-block border-b-4 text-3xl hover:border-primarylight cursor-pointer pt-3"
          :class="title === 'Home' ? 'border-primary' : 'border-white'"
        >
          <font-awesome-icon
            icon="home"
            class=""
            :class="title === 'Home' ? 'text-primary' : 'text-black'"
          />
          <span v-if="expand" class="ml-2 text-lg font-bold">Home</span>
        </a>
      </div>
      <div v-if="user.Attendance > 0" class="w-full">
        <a
          href="/attendance"
          class="inline-block border-b-4 text-3xl hover:border-primarylight cursor-pointer pt-3"
          :class="title === 'Anwesenheit' ? 'border-primary' : 'border-white'"
        >
          <font-awesome-icon
            icon="person-circle-check"
            class=""
            :class="title === 'Anwesenheit' ? 'text-primary' : 'text-black'"
          />
          <span v-if="expand" class="ml-2 text-lg font-bold">Anwesenheit</span>
        </a>
      </div>
      <div v-if="user.Mealplan > 0" class="w-full">
        <a
          href="/mealplanning"
          class="inline-block border-b-4 text-3xl hover:border-primarylight cursor-pointer pt-3"
          :class="title === 'Essensplanung' ? 'border-primary' : 'border-white'"
        >
          <font-awesome-icon
            icon="utensils"
            class=""
            :class="title === 'Essensplanung' ? 'text-primary' : 'text-black'"
          />
          <span v-if="expand" class="ml-2 text-lg font-bold"
            >Essensplanung</span
          >
        </a>
      </div>
      <div v-if="user.Mealplan > 0" class="w-full">
        <a
          href="/export"
          class="inline-block border-b-4 text-3xl hover:border-primarylight cursor-pointer pt-3"
          :class="title === 'Exporte' ? 'border-primary' : 'border-white'"
        >
          <font-awesome-icon
            icon="file-export"
            class=""
            :class="title === 'Exporte' ? 'text-primary' : 'text-black'"
          />
          <span v-if="expand" class="ml-2 text-lg font-bold"
            >Exporte</span
          >
        </a>
      </div>
      <div class="w-full mt-20">
        <a
          :href="evlineUrl"
          class="inline-block border-b-4 text-3xl hover:border-primarylight cursor-pointer pt-3 border-white"
        >
          <font-awesome-icon icon="lock" class="text-black" />
          <span v-if="expand" class="ml-2 text-lg font-bold"
            >Zu "Anmeldung"</span
          >
        </a>
      </div>
    </div>

    <div class="absolute left-0 bottom-0 w-full text-center">
      <div class="">
        <button v-if="expand" class="full" @click="logout">Abmelden</button>
        <font-awesome-icon
          v-else
          class="text-3xl text-red-300 cursor-pointer mb-2"
          icon="right-from-bracket"
        />
      </div>
      <a
        v-if="expand"
        href="/impressum"
        class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold"
        >Impressum</a
      >
      <a
        v-if="expand"
        href="/datenschutz"
        class="text-sm mx-3 underline cursor-pointer text-gray-500 font-bold"
        >Datenschutz</a
      >
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Header",
  props: ["title", "user", "expand"],
  data() {
    return {
      evlineUrl: process.env.VUE_APP_INTERNAL_URL,
    };
  },
  methods: {
    logout() {
      API.logout((r, status) => {
        if (status === 200) {
          window.location = "/";
        }
      });
    },
  },
};
</script>
  